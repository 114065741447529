import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "documentation-practices"
    }}>{`Documentation practices`}</h1>
    <h2 {...{
      "id": "what-should-readmemd-contain"
    }}>{`What should README.md contain?`}</h2>
    <ul>
      <li parentName="ul">{`Short description of what the service or product is`}</li>
      <li parentName="ul">{`Installation instructions`}<ul parentName="li">
          <li parentName="ul">{`How to run the software from the ready-made Docker image`}</li>
          <li parentName="ul">{`Prerequisites`}</li>
          <li parentName="ul">{`How to get the software up and running locally`}</li>
          <li parentName="ul">{`Dependent services needed to run (pointers)`}</li>
          <li parentName="ul">{`Configuration instructions`}</li>
        </ul></li>
      <li parentName="ul">{`How to enable debug output`}</li>
      <li parentName="ul">{`Links to public information`}<ul parentName="li">
          <li parentName="ul">{`Where the issue tracking is i.e. link to backlog`}</li>
          <li parentName="ul">{`API documentation (OpenAPI / GraphiQL)`}</li>
          <li parentName="ul">{`Whether builds are working or failing`}</li>
          <li parentName="ul">{`Links to CI/CD and monitoring dashboards`}</li>
        </ul></li>
      <li parentName="ul">{`How to contribute to the software (PR and whom to contact if it's not getting merged), can be a separate CONTRIBUTING.md`}</li>
      <li parentName="ul">{`Contact points, people responsible or a role (email?) address for its developers - e.g. service-dev@hel.fi.`}</li>
      <li parentName="ul">{`Open dev / test / staging / production environments (to try it out)`}<ul parentName="li">
          <li parentName="ul">{`Frontend links to "normal user" and staff / admin user entry points`}</li>
          <li parentName="ul">{`Backend links to interactive API browser (swagger/GraphiQL/Django's API browser)`}</li>
        </ul></li>
      <li parentName="ul">{`Connected services (dependents and dependees)`}</li>
    </ul>
    <h2 {...{
      "id": "what-should-contributingmd-contain"
    }}>{`What should CONTRIBUTING.md contain?`}</h2>
    <p>{`Example template:`}</p>
    <pre><code parentName="pre" {...{}}>{`Contributions are accepted as pull requests.  Please observe our coding
practices at https://github.com/City-of-Helsinki/bestpractice/ .
Please make your pull requests short, elegant and only handling one
issue at a time!
 
If you make a pull request, you may also want to contact
<INSERT SERVICE'S ROLE ADDRESS> to tell about your contribution.
 
Our contribution handling guidelines are at
https://github.com/City-of-Helsinki/bestpractice/blob/master/accepting-contributions.md
`}</code></pre>
    <p>{`Also see the contributing.md of `}<a parentName="p" {...{
        "href": "https://github.com/City-of-Helsinki/bestpractice/blob/master/.github/contributing.md"
      }}>{`this developer site`}</a>{`.`}</p>
    <h2 {...{
      "id": "github-templates"
    }}>{`GitHub templates`}</h2>
    <p>{`Templates for `}<a parentName="p" {...{
        "href": "https://github.com/City-of-Helsinki/bestpractice/blob/master/.github/pull_request_template.md"
      }}>{`pull requests`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/City-of-Helsinki/bestpractice/blob/master/.github/ISSUE_TEMPLATE/bug_report.md"
      }}>{`bug reports`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/City-of-Helsinki/bestpractice/blob/master/.github/ISSUE_TEMPLATE/feature_request.md"
      }}>{`feature requests`}</a>{` can be found `}<a parentName="p" {...{
        "href": "https://github.com/City-of-Helsinki/bestpractice/tree/master/.github"
      }}>{`in the repository`}</a>{`. Modify these to suit the project if needed. Read more about templates on `}<a parentName="p" {...{
        "href": "https://help.github.com/en/github/building-a-strong-community/about-issue-and-pull-request-templates"
      }}>{`GitHub`}</a>{`.`}</p>
    <h3 {...{
      "id": "pull-request-template"
    }}>{`Pull request template`}</h3>
    <pre><code parentName="pre" {...{}}>{`## Description

## Issues
### Closes
**[DEV-XXX](https://helsinkisolutionoffice.atlassian.net/browse/DEV-XXX):** 

### Related

## Testin
### Automated tests

### Manual testing

## Screenshots

## Additional notes

`}</code></pre>
    <h3 {...{
      "id": "bug-report"
    }}>{`Bug report`}</h3>
    <pre><code parentName="pre" {...{}}>{`**Describe the bug**
A clear and concise description of what the bug is.

**To Reproduce**
Steps to reproduce the behavior:
1. Go to '...'
2. Click on '....'
3. Scroll down to '....'
4. See error

**Expected behavior**
A clear and concise description of what you expected to happen.

**Screenshots**
If applicable, add screenshots to help explain your problem.

**Desktop (please complete the following information):**
 - OS: [e.g. iOS]
 - Browser [e.g. chrome, safari]
 - Version [e.g. 22]

**Smartphone (please complete the following information):**
 - Device: [e.g. iPhone6]
 - OS: [e.g. iOS8.1]
 - Browser [e.g. stock browser, safari]
 - Version [e.g. 22]

**Additional context**
Add any other context about the problem here.

`}</code></pre>
    <h3 {...{
      "id": "feature-request"
    }}>{`Feature request`}</h3>
    <pre><code parentName="pre" {...{}}>{`**Is your feature request related to a problem? Please describe.**
A clear and concise description of what the problem is. Ex. I'm always frustrated when [...]

**Describe the solution you'd like**
A clear and concise description of what you want to happen.

**Describe alternatives you've considered**
A clear and concise description of any alternative solutions or features you've considered.

**Additional context**
Add any other context or screenshots about the feature request here.

`}</code></pre>
    <h2 {...{
      "id": "api-documentation"
    }}>{`API documentation`}</h2>
    <p>{`REST APIs should be documented according to the `}<a parentName="p" {...{
        "href": "https://www.openapis.org/"
      }}>{`OpenAPI specification`}</a>{` (formerly Swagger specification).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      